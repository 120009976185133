import React, { useRef } from "react";
import style from "./style.module.scss";

interface IRadioProps {
  orValue: string;
  value: string;
  name: string;
  // updateValue: (value: string) => void;
  homePage?: boolean;
}

function Radio({ orValue, value, name, homePage }: IRadioProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  // const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   updateValue(e.target.value);
  // };
  const checked = orValue === value;

  return (
    <div onClick={() => inputRef.current?.click()}>
      <div
        className={`${style.checkBox} ${checked ? " " + style.active : ""} ${
          homePage ? style.homepage : ""
        } `}
      ></div>
      <input
        ref={inputRef}
        className="d-none"
        type="radio"
        // onChange={handleUpdate}
        name={name}
        value={orValue}
      />
    </div>
  );
}

export default Radio;
