import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getProdData} from "../../api";
import {
    IAllDataApi,
    THomePageStatus,
    TMagzineOption,
    TStatusFlag,
} from "../../interface";

export interface IdetailStatus {
    videoStatus: boolean;
    surveyStatus: boolean;
    headerStatus: boolean;
    counterStatus: boolean;
    magazineStatus: boolean;
    homePageStatus: THomePageStatus;
    magazineOption: TMagzineOption;
    iOSPopupStatus: boolean;
    androidPopupStatus: boolean;
}

export const fetchProdStatus = createAsyncThunk(
    "statusController/fetchProdStatus",
    async (thunkAPI) => {
        const response = await getProdData();
        return response.data;
    }
);

const initialState: IdetailStatus = {
    magazineStatus: false,
    videoStatus: true,
    surveyStatus: false,
    headerStatus: false,
    counterStatus: false,
    magazineOption: "default",
    homePageStatus: "drama",
    iOSPopupStatus: false,
    androidPopupStatus: false,
};

export const detailStatusSlice = createSlice({
    name: "detailStatus",
    initialState,

    reducers: {
        toggleDetail: (state, action: PayloadAction<TStatusFlag>) => {
            return {...state, [action.payload]: !state[action.payload]};
        },
        setHomePageStatus: (state, action: PayloadAction<THomePageStatus>) => {
            return {...state, homePageStatus: action.payload};
        },
        setMazgaineOption: (state, action: PayloadAction<TMagzineOption>) => {
            return {...state, magazineOption: action.payload};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(
            fetchProdStatus.fulfilled,
            (state: any, action: PayloadAction<IAllDataApi>) => {
                const {
                    headerStatus,
                    mobile,
                    surveyStatus,
                    videoStatus,
                    homePageStatus,
                    iOSPopupStatus,
                    androidPopupStatus,
                    magazineStatus,
                    magazineOption,
                } = action.payload;
                return {
                    ...state,
                    headerStatus,
                    surveyStatus,
                    iOSPopupStatus,
                    androidPopupStatus,
                    videoStatus,
                    mobile,
                    counterStatus: action.payload.header.counterStatus,
                    homePageStatus,
                    magazineStatus,
                    magazineOption,
                };
            }
        );
    },
});

export const {toggleDetail, setHomePageStatus, setMazgaineOption} =
    detailStatusSlice.actions;

export default detailStatusSlice.reducer;
