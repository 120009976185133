import {configureStore, ThunkAction, Action} from "@reduxjs/toolkit";
import videoReducer from "../features/video/videoSlice";
import mobileSlice from "../features/mobile/mobileSlice";
import detailStatusReducer from "../features/StatusController/StatusControllerSlice";
import HeaderReducer from "../features/header/headerSlice";
import counterslice from "../features/Counter/counterslice";
import surveySlice from "../features/Survey/surveySlice";
import {dalSlice} from "../features/header/headerApi";
import {dalSurveySlice} from "../features/Survey/surveyApi";
import previewReducer from "../features/PreviewPage/previewSlice";

export const store = configureStore({
    reducer: {
        videoOption: videoReducer,
        detailStatus: detailStatusReducer,
        header: HeaderReducer,
        counter: counterslice,
        survey: surveySlice,
        mobile: mobileSlice,
        [dalSlice.reducerPath]: dalSlice.reducer,
        [dalSurveySlice.reducerPath]: dalSurveySlice.reducer,
        preview: previewReducer,
    },
    middleware: (getDeafultMiddleware) => {
        return getDeafultMiddleware().concat(dalSlice.middleware);
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
