import React, { useRef } from "react";
import style from "./style.module.scss";

interface ICheckBoxProps {
  value: boolean;
  updateValue: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
}

function CheckBox({ value, updateValue, id }: ICheckBoxProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div
      className={`${style.checkBox}${value ? " " + style.active : ""}`}
      onClick={() => inputRef.current?.click()}
    >
      <input
        ref={inputRef}
        className="d-none"
        type="checkbox"
        checked={value}
        onChange={updateValue}
        id={id}
      />
    </div>
  );
}

export default CheckBox;
