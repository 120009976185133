import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setHomePageStatus } from "../../features/StatusController/StatusControllerSlice";
import { THomePageStatus } from "../../interface";
import Radio from "../Common/Radio";
import style from "./HomePageStatus.module.css";
const HomePageStatus = (): JSX.Element => {
  const state = useAppSelector((state) => state.detailStatus.homePageStatus);
  const dispatch = useAppDispatch();

  const handleHomePageVal = (value: THomePageStatus) => {
    dispatch(setHomePageStatus(value));
  };

  return (
    <ul className={style.container}>
      <li onClick={() => handleHomePageVal("regular")}>
        <Radio
          homePage
          orValue={"regular"}
          value={state}
          name={"homePageStatus"}
        />
        <img
          src={
            state === "regular"
              ? "/assets/regular-on.webp"
              : "/assets/regular-off.webp"
          }
          alt=""
          height="auto"
          width="200px"
        />
      </li>
      <li onClick={() => handleHomePageVal("drama")}>
        <Radio
          homePage
          orValue={"drama"}
          value={state}
          name={"homePageStatus"}
        />
        <img
          src={
            state === "drama"
              ? "/assets/drama-on.webp"
              : "/assets/drama-off.webp"
          }
          alt=""
          height="auto"
          width="200px"
        />
      </li>
      <li onClick={() => handleHomePageVal("hagigi")}>
        <Radio
          homePage
          orValue={"hagigi"}
          value={state}
          name={"homePageStatus"}
        />
        <img
          src={
            state === "hagigi"
              ? "/assets/blue-on.webp"
              : "/assets/blue-off.webp"
          }
          alt=""
          height="auto"
          width="200px"
        />
      </li>
    </ul>
  );
};

export default HomePageStatus;
