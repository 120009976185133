import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchProdStatus} from "../StatusController/StatusControllerSlice";
import {IAllDataApi} from "../../interface";


export interface IMobileStatus {
    id?: number;
    ios_times: number;
    android_times: number
}

const initialState: IMobileStatus = {
    ios_times: 1,
    android_times: 1
};

export const mobileSlice = createSlice({
    name: "mobile",
    initialState,
    reducers: {
        updatePopupTimes: (
            state,
            action: PayloadAction<{
                name: "ios_times" | "android_times";
                value: string;
            }>
        ) => {
            return {...state, [action.payload.name]: action.payload.value};
        },
    },

    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(
            fetchProdStatus.fulfilled,
            (state: any, action: PayloadAction<IAllDataApi>) => {
                // console.log(action.payload.video);
                return {
                    ...action.payload.mobile,
                };
            }
        );
    },
});

export const {
    updatePopupTimes,
} = mobileSlice.actions;

export default mobileSlice.reducer;
