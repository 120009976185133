import React, { useRef } from "react";
import Radio from "..";
import style from "./style.module.css";

interface RadioWLabelProps {
  handleUpdate: (value: string) => void;
  value: string;
  orValue: string;
  labelName: string;
  group: string;
  noMargin?: boolean;
}

function RadioWLabel({
  handleUpdate,
  value,
  orValue,
  labelName,
  group,
  noMargin,
}: RadioWLabelProps): JSX.Element {
  return (
    <div
      className={`${style.container} ${noMargin && style.noMargin}`}
      onClick={() => handleUpdate(orValue)}
    >
      <Radio orValue={orValue} value={value} name={group} />
      <label className={style.radioLable} htmlFor={orValue}>
        {labelName}
      </label>
    </div>
  );
}

export default RadioWLabel;
