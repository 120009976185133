import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAllDataApi } from "../../interface";
import { fetchProdStatus } from "../StatusController/StatusControllerSlice";

export interface IheaderStat {
  imageId: string;
}

const initialState: IheaderStat = {
  imageId: "",
};

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    updateMedia: (state, action: PayloadAction<string>) => {
      return { ...state, imageId: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProdStatus.fulfilled,
      (state: any, action: PayloadAction<IAllDataApi>) => {
        // console.log(action.payload.header);
        return { ...state, imageId: action.payload.header.imageId };
      }
    );
  },
});

export const { updateMedia } = headerSlice.actions;

export default headerSlice.reducer;
