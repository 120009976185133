import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  IDAlResponse,
  IElectionSurvey,
  IElectionSample,
} from "../../interface";

const DAL_URL = process.env.REACT_APP_DAL_URL || 'https://dal.walla.co.il';

export const dalSurveySlice = createApi({
  reducerPath: "dal/survey",
  baseQuery: fetchBaseQuery({ baseUrl: DAL_URL }),
  endpoints: (builder) => ({
    /**
     * Get image by media id from the DAL
     * It wrapped ito mutation, because we need to
     * get this data after user put some values into
     * the specific input. And we can call it only throw
     * mutation way
     */
    getSurvey: builder.mutation<IDAlResponse<IElectionSurvey>, string>({
      query: (id: string) => ({
        url: `/elections/surveys/${id}`,
        method: "GET",
      }),
    }),
    getRealSurvey: builder.mutation<IDAlResponse<IElectionSurvey>, void>({
      query: () => ({
        url: `/elections/surveys/-1`,
        method: "GET",
      }),
    }),
    getElectionSample: builder.mutation<IDAlResponse<IElectionSample>, void>({
      query: () => ({
        url: `/elections/surveys/elections`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetSurveyMutation, useGetRealSurveyMutation, useGetElectionSampleMutation } =
  dalSurveySlice;
