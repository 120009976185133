import {IMandatsObj} from "../interface";

export const getArrayOfN = (n: number): number[] => {
  return Array.from(Array(n).keys());
};

export const calculateTimeLeft = (
  countDown: number
): { days: string; hours: string; minutes: string } => {

  if (countDown <= 0) {
    return {
      days: '00',
      hours: '00',
      minutes: '00',
    }
  }

  const deltaDate = new Date(countDown);
  const oneDay = 1000 * 60 * 60 * 24;

  const days =  Math.floor( countDown / oneDay);
  const hours = deltaDate.getUTCHours();
  const minutes = deltaDate.getUTCMinutes() + 1;

  return {
    days: days < 10 ? `0${days}` : `${days}`,
    hours: hours < 10 ? `0${hours}` : `${hours}`,
    minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
  }
};

export const getDate = (today: Date): string => {
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return yyyy + "-" + dd + "-" + mm;
};

export const sortdata = <T>(a: T, b: T): number => {
  return a > b ? -1 : b > a ? 1 : 0;
};

export const englishToHebrewOwners: { [key: string]: string } = {
  maariv: "מעריב ",
  "radio-103": "רדיו 103 ",
  walla: "וואלה!",
  "kan-11": "כאן 11 ",
  "aruc-12": "ערוץ 12 ",
  "reshet-13": "רשת 13 ",
};
export const getEditorHebName = (name: string): string => {
  return englishToHebrewOwners[name];
};

export const getSurveyEditorHebName = (name: keyof IMandatsObj) => {
  switch (name) {
    case "sb_walla":
      return "וואלה";
    case "sb_kan":
      return "כאן 11";
    case "sb_reshet":
      return "רשת 13";
    case "sb_en":
      return "קשת 12";
    default:
      return "וואלה";
  }
};

export const getAvg = (grades: number[]): number => {
  let avg = 0;
  let iterator: any;
  for (iterator in grades) {
    avg = avg + grades[iterator];
  }
  return avg / 4;
};
