import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IDAlResponse } from "../../interface";

const DAL_URL = process.env.REACT_APP_DAL_URL || 'https://dal.walla.co.il';

export const dalSlice = createApi({
  reducerPath: "dal/api",
  baseQuery: fetchBaseQuery({ baseUrl: DAL_URL }),
  endpoints: (builder) => ({
    /**
     * Get image by media id from the DAL
     * It wrapped ito mutation, because we need to
     * get this data after user put some values into
     * the specific input. And we can call it only throw
     * mutation way
     */
    getImage: builder.mutation<IDAlResponse<string>, string>({
      query: (imageId: string) => ({
        url: `/elections/${imageId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetImageMutation } = dalSlice;
