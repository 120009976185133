import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  toggleData,
  updateVideoRadio,
} from "../../../features/video/videoSlice";
import { IVideoData } from "../../../interface";
import CheckBox from "../CheckBox";
import RadioWLabel from "../Radio/RadioWLabel/RadioWLabel";
import style from "./style.module.css";

interface VideoFormProps extends IVideoData {}

const VideoForm = ({
  id,
  name,
  options,
  group,
  checkBoxName,
}: VideoFormProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const Tdata = useAppSelector((state) => state.videoOption[id]);
  const checkBoxValue = useAppSelector((state) => {
    if (checkBoxName) {
      return state.videoOption[checkBoxName];
    }
    return null;
  });
  const updateData = (value: any) => {
    dispatch(
      updateVideoRadio({
        name: id,
        value,
      })
    );
  };
  const updateCheckBox = () => {
    if (checkBoxName) {
      dispatch(toggleData(checkBoxName));
    }
  };

  return (
    <>
      <div className={style.wrapHeader}>
        {checkBoxName ? (
          <>
            <CheckBox
              value={checkBoxValue!}
              id={id}
              updateValue={updateCheckBox}
            />

            <label htmlFor={id} className={style.header}>
              {name}
            </label>
          </>
        ) : (
          <p className={style.lonlyHeader}>{name}</p>
        )}
      </div>

      <form className={style.form}>
        {options.map((data, i) => {
          return (
            <RadioWLabel
              key={i}
              handleUpdate={(e) => updateData(e)}
              value={Tdata}
              orValue={data.value}
              labelName={data.name}
              group={group}
            />
          );
        })}
      </form>
    </>
  );
};
export default VideoForm;
