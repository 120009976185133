import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAllDataApi, ISurveyStat } from "../../interface";
import { fetchProdStatus } from "../StatusController/StatusControllerSlice";

export type surveyProperty = "option" | "linkContent" | "url" | "surveyId";

export interface surveyState {
  value: number;
  status: "idle" | "loading" | "failed";
}

const initialState: ISurveyStat = {
  option: "survey",
  linkContent: "",
  url: "",
  surveyId: "",
};
export const surveySlice = createSlice({
  name: "survey",
  initialState,

  reducers: {
    updateSurvey: (
      state,
      action: PayloadAction<{ name: surveyProperty; value: any }>
    ) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProdStatus.fulfilled,
      (state, action: PayloadAction<IAllDataApi>) => {
        return {
          ...action.payload.survey,
        };
      }
    );
  },
});

export const { updateSurvey } = surveySlice.actions;

export default surveySlice.reducer;
