import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TInitial = boolean;
const initialState: TInitial = false;
export const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    togglePreview: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    },
  },
});

export const { togglePreview } = previewSlice.actions;

export default previewSlice.reducer;
