import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IAllDataApi,
  TDesktopPosition,
  TMobilePosition,
} from "../../interface";
import { fetchProdStatus } from "../StatusController/StatusControllerSlice";

export interface videoState {
  value: number;
  status: "idle" | "loading" | "failed";
}

export interface IVideoStat {
  id?: number;
  autoPlay: boolean;
  advertising: boolean;
  desktopStatus: boolean;
  desktopPosition: TDesktopPosition;
  mobileStatus: boolean;
  mobilePosition: TMobilePosition;
  source: string;
  sourceLink?: string;
  desktopFirstItem?: string;
  desktopSecondItem?: string;
  editorId?: string;
}

const initialState: IVideoStat = {
  autoPlay: true,
  advertising: true,
  desktopStatus: true,
  desktopPosition: "middle",
  mobileStatus: true,
  mobilePosition: "top",
  source: "https://live.wcdn.co.il/ulpan/prog_index.m3u8",
  sourceLink: "",
  desktopFirstItem: "",
  desktopSecondItem: "",
  editorId: "",
};

export const videoSlice = createSlice({
  name: "video",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions

  reducers: {
    updateVideoRadio: (
      state,
      action: PayloadAction<{ name: any; value: any }>
    ) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },
    toggleData: (
      state,
      action: PayloadAction<
        "autoPlay" | "advertising" | "desktopStatus" | "mobileStatus"
      >
    ) => {
      return { ...state, [action.payload]: !state[action.payload] };
    },
    updateVideoString: (
      state,
      action: PayloadAction<{
        name: "desktopFirstItem" | "desktopSecondItem" | "sourceLink" | "editorId";
        value: string;
      }>
    ) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(
      fetchProdStatus.fulfilled,
      (state: any, action: PayloadAction<IAllDataApi>) => {
        // console.log(action.payload.video);
        return {
          ...action.payload.video,
        };
      }
    );
  },
});

export const {
  updateVideoRadio,
  toggleData,
  updateVideoString,
  // updateMoreItems,
} = videoSlice.actions;

export default videoSlice.reducer;
