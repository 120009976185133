import axios from "axios";
import { IAllDataApi } from "../interface";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

export const getProdData = () => instance.get<IAllDataApi>("/state/1");
export const updateProdData = (body: any) => instance.patch("/state/1", body);
