import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getDate } from "../../helper";
import { CounterProperty, IAllDataApi, ICounterState } from "../../interface";
import { fetchProdStatus } from "../StatusController/StatusControllerSlice";

export interface counterState {
  value: number;
  status: "idle" | "loading" | "failed";
}

const initialState: ICounterState = {
  counterHeader: "",
  dateTarget: getDate(new Date()),
  minutes: 0,
  hours: 0,
  counterColor: "black",
};
export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    updateCounter: (
      state,
      action: PayloadAction<{ name: CounterProperty; value: any }>
    ) => {
      return { ...state, [action.payload.name]: action.payload.value };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProdStatus.fulfilled,
      (state, action: PayloadAction<IAllDataApi>) => {
        const { counterColor, counterHeader, dateTarget, minutes, hours } =
          action.payload.header;
        return {
          ...state,
          counterColor,
          counterHeader,
          dateTarget,
          minutes,
          hours,
        };
      }
    );
  },
});

export const { updateCounter } = counterSlice.actions;

export default counterSlice.reducer;
