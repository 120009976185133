// "desktop" | "mobile" | "soruce"

import { IVideoData } from "../interface";

export const desktopData: IVideoData = {
  name: "Desktop",
  id: "desktopPosition",
  group: "desktop",
  options: [
    {
      value: "middle",
      name: "מעל הטעימות",
    },
    {
      value: "bottom",
      name: "מתחת לטעימות",
    },
  ],
};

export const mobileData: IVideoData = {
  name: "Mobile",
  id: "mobilePosition",
  group: "mobile",
  options: [
    {
      value: "top",
      name: "מעל ראשית",
    },
    {
      value: "middle",
      name: "מתחת לטעימות",
    },
    {
      value: "bottom",
      name: "מתחת לחדשות",
    },
  ],
};

export const sourceData: IVideoData = {
  name: "מקורות שידור",
  id: "source",
  group: "source",
  options: [
    {
      value: "https://live.wcdn.co.il/ulpan/prog_index.m3u8",
      name: "ulpan",
    },
    {
      value: "https://live.wcdn.co.il/news/prog_index.m3u8",
      name: "news",
    },
    {
      value: "https://live.wcdn.co.il/news2/prog_index.m3u8",
      name: "news2",
    },
    {
      value: "https://live.wcdn.co.il/yes/prog_index.m3u8",
      name: "yes",
    },
    {
      value: "https://cdn-uw2-prod.tsv2.amagi.tv/linear/amg01742-walla-wallanews-ono/playlist.m3u8",
      name: "וואלה +",
    },
  ],
};
